import React, { useState, useCallback } from 'react';
import ReactInputMask from 'react-input-mask';

import arrow from "../Assets/Icons/ic_arrow_left.svg";
import CustomInput from '../Components/CustomInput';
import drop from "../Assets/Icons/ic_drop.svg";
import { DatePicker, Select, Tooltip } from 'antd';
import moment from 'moment';

import './Company.css';

const RequesterRoleEnum = {
  'Sócio': 1,
  'Sócio administrador': 2,
  'Outros': 3
}

const cities = [
  'Porto Alegre', 'Alvorada', 'Cachoeirinha', 'Canoas', 'Eldorado do Sul', 'Nova Santa Rita', 'Triunfo', 'Viamão', 'Nenhuma das alternativas anteriores'
]

function CnpjValid(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '') || '';

  if (cnpj.length !== 14) return false;

  if (cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999")
      return false;

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
          pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0)) return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
      return false;

  return true;

}

function Company(props) {
  const [CompanyCnpj, setCompanyCnpj] = useState('')
  const [CompanyCnpjFoundation, setCompanyCnpjFoundation] = useState()
  const [CompanyRevenues, setCompanyRevenues] = useState(0)
  const [RequesterRoleType, setRequesterRole] = useState(0)
  const [CityName, setCityName] = useState('')
  const [RequesterRoleDescription, setRequesterRoleDescription] = useState('')

  const goBack = () => props.setStage(2)
  const goNext = () => props.setStage(4, {CompanyCnpj, CompanyRevenues, RequesterRoleType, RequesterRoleDescription, CityName, CompanyCnpjFoundation})

  const isCnpjValid = useCallback(CnpjValid, [CompanyCnpj])

  const disabledFutureDate = (current) => {
    let today = moment();
    return current && current > moment(today, "YYYY-MM-DD");
  }

  const hasOneYear = () => {
    if(CompanyCnpjFoundation) {
        const leastOneYear = moment().add(-1, 'year')
        return (CompanyCnpjFoundation && CompanyCnpjFoundation < leastOneYear)
    }
    else return true
  }

  const availableLocation = CityName !== 'Nenhuma das alternativas anteriores'

  return (
    <div className="company">
      <div className="form-title">
        <img src={arrow} className="go-back" alt="go back" onClick={goBack}/>
        <h1>agora, sobre tua empresa.</h1>
      </div>
      <Tooltip
        title="CNPJ inválido"
        placement="bottomLeft"
        visible={CompanyCnpj.replace(/[^\d]+/g,'').length === 14 && !isCnpjValid(CompanyCnpj)}
      >
        <ReactInputMask
          value={CompanyCnpj || ''}
          onChange={e => setCompanyCnpj(e.target.value)}
          mask="99.999.999/9999-99"
        >{
          p => <CustomInput title placeholder="CNPJ" {...p}/>
        }</ReactInputMask>
      </Tooltip>
      <h3>CNPJ precisa estar ativo por mais de 1 ano.</h3>
      <DatePicker
        className="custom-datepicker"
        placeholder="Data de Fundação"
        format="DD/MM/YYYY"
        value={CompanyCnpjFoundation} 
        onChange={setCompanyCnpjFoundation}
        showToday={false}
        allowClear={false}
        disabledDate={disabledFutureDate}        
      />
      {!hasOneYear() &&
        <div className="error">
            <div className="arrow-up"></div> 
            <div className="error-message">
                Infelizmente não é possível finalizar a tua solicitação, pois é necessário que a tua empresa tenhas pelo menos 1 ano de existência. Esperamos poder construir uma parceria em um futuro próximo e desejamos sucesso ao teu negócio.
            </div>
        </div>}
      <h2>Em qual cidade a tua empresa está registrada?</h2>
      <Select 
        onChange={setCityName} 
        placeholder="clique para escolher" 
        className="custom-select" 
        suffixIcon={<img src={drop} alt="dropdown"/>}
      >
        {cities.map(e => <Select.Option key={e}>{e}</Select.Option>)}
      </Select>
      {!availableLocation &&
        <div className="error">
            <div className="arrow-up"></div> 
            <div className="error-message">
            Infelizmente não é possível finalizar a tua solicitação. A GIV que está enquadrada no modelo ESC e, por isso, só podemos ceder crédito para empresas que estejam registradas nas cidades listadas anteriormente.    
            </div>
        </div>}
      <h3>* Caso não tenha encontrado a cidade da sua empresa na lista, não se preocupe, pois em breve atenderemos tua região.</h3>
      <Tooltip
        title='A renda mínima precisa ser maior ou igual a R$ 2,100.00' 
        placement="bottomLeft" 
        visible={!!CompanyRevenues && CompanyRevenues < 2100}
      >
        <CustomInput
          title
          placeholder="faturamento mensal"
          value={!!CompanyRevenues ? `R$ ${CompanyRevenues.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : ''}
          onChange={e => setCompanyRevenues(parseInt(e.target.value.replace(/(\D|[R$ .,])/g, ''), 10) / 100 || 0)}
        />
      </Tooltip>
      <h3>Informação opcional. Mínimo de R$ 2.100,00 por mês.</h3>
      <h2>qual tua relação com a empresa?</h2>
      <Select 
        onChange={v => setRequesterRole(parseInt(v, 10))} 
        placeholder="clique para escolher" 
        className="custom-select" 
        suffixIcon={<img src={drop} alt="dropdown"/>}
      >
        {Object.entries(RequesterRoleEnum).map(el => <Select.Option key={el[1]}>{el[0]}</Select.Option>)}
      </Select>
      {RequesterRoleType === 3 ? (
        <CustomInput
          placeholder="qual?"
          value={RequesterRoleDescription || ''}
          onChange={e => setRequesterRoleDescription(e.target.value)}
        />
      ) : null}
      <button 
        disabled={
          !CompanyCnpjFoundation ||
          !hasOneYear(CompanyCnpjFoundation) ||
          !RequesterRoleType || 
          !CityName ||
          !availableLocation ||
          CompanyRevenues < 2100 ||
          !isCnpjValid(CompanyCnpj)
        } 
        className="custom-button" 
        onClick={goNext}
      >próximo</button>
    </div>
  )
}

export default Company