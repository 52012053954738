import React, { useState } from 'react';

import slogan from './Assets/Icons/slogan_giv.svg';
import { Anchor, Icon, Drawer } from 'antd';

import './Home.css';

function Home () {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <section id="home">
        {window.innerWidth > 800 ? (
          <Anchor targetOffset={window.innerHeight * 0.05}>
            <h1 className="icon-ic_logo_giv"> </h1>
            <div className="menu">
              <Anchor.Link href="#home" title="Home"/>
              <Anchor.Link href="#about" title="Quem Somos"/>
              <Anchor.Link href="#method" title="Como Funciona"/>
              <Anchor.Link href="#comments" title="Depoimentos"/>
              <Anchor.Link href="#borrow" title="Quero Empréstimo"/>
              <Anchor.Link href="#contact" title="Contato"/>
            </div>
          </Anchor>
        ):(
          <>
            <div className="navigation-mobile">
              <h1 className="icon-ic_logo_giv"> </h1>
              <Icon type="menu" onClick={() => setDrawerOpen(true)}/>
            </div>
            <Drawer
              placement="left"
              closable
              onClose={() => setDrawerOpen(false)}
              visible={drawerOpen}
            > 
              <div className="mobile-menu">
                <Anchor onClick={() => setDrawerOpen(false)}>
                  <h1 className="icon-ic_logo_giv"> </h1>
                  <Anchor.Link href="#home" title="Home"/>
                  <Anchor.Link href="#about" title="Quem Somos"/>
                  <Anchor.Link href="#method" title="Como Funciona"/>
                  <Anchor.Link href="#comments" title="Depoimentos"/>
                  <Anchor.Link href="#borrow" title="Quero Empréstimo"/>
                  <Anchor.Link href="#contact" title="Contato"/>
                </Anchor>
              </div>
            </Drawer>
          </>
        )}

      <div className="landing">
        <div className="text">
          <h1>Crédito simples para empresas</h1>
          <h2>Agora o teu negócio pode contar com uma opção de crédito que vai contribuir para que teu sonho se torne realidade.</h2>
          <a href="#borrow">quero empréstimo</a>
        </div>
        <div className="logo">
          <img src={slogan} alt="slogan" className="slogan"/>
        </div>
      </div>
    </section>
  )
}

export default Home