import React, { useState, useContext } from 'react';
import arrow from "../Assets/Icons/ic_arrow_left.svg";
import './Confirm.css';
import { Tooltip, Checkbox } from 'antd';
import CustomInput from '../Components/CustomInput';
import { PolicyAndTermsModalCtx } from '../App';
import { baseTax } from './FirstStage'

const backUrl = process.env.REACT_APP_API_URL

function Confirm(props) {
  const [RequesterEmail, setRequesterEmail] = useState(props.loanData.RequesterEmail)
  const [terms, setTerms] = useState(false)

  const openModal = useContext(PolicyAndTermsModalCtx)

  const goBack = () => props.setStage(3, {})
  const goNext = () => props.setStage(5, {})
  const format = a => a.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})

  const sendData = () => {
    window.gtag('event', 'conversion', { 'send_to': 'AW-676971486/E8_KCIO6k8ABEN6H58IC' });


    const data = {
      ...props.loanData,
      RequesterEmail,
      CompanyCnpj: props.loanData.CompanyCnpj.replace(/([./-])/g, ''),
      CompanyCnpjFoundation: props.loanData.CompanyCnpjFoundation.format('YYYY-MM-DD'),
      RequesterCpf: props.loanData.RequesterCpf.replace(/([.-])/g, ''),
      RequesterPhone: props.loanData.RequesterPhone.replace(/([() -])/g, '')
    }

    const query = window.location.href.split('?')[1]
    if(query) {
      const utm = query.split('#')[0]
      if(utm) {
        const params = utm.split('&')
        const campaign = Object.fromEntries(params.map(p => p.split('=')))

        const Name = campaign.utm_campaign
        const Source = campaign.utm_source
        const Medium = campaign.utm_medium
        const Content = campaign.utm_content
        const Term = campaign.utm_term

        if(Source && Medium && Name) {
          data.Campaign = {
            Source,
            Medium,
            Name,
            Term,
            Content,
          }
        }
      }
    }

    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open("POST", `${backUrl}/Request`)
    xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
    xmlhttp.send(JSON.stringify(data))
    goNext()
  }

  return (
    <div className="confirm">
      <div className="form-title">
        <img src={arrow} className="go-back" alt="go back" onClick={goBack}/>
        <h1>tudo certo até aqui?</h1>
      </div>
      <h2>Para analisar o teu pedido, precisamos que você confira algumas informações e aceite os termos.</h2>
      <div className="card">
        <div className="summary">
          <h3>Resumo da simulação</h3>
          <div className="data">
            <h5>Valor do empréstimo</h5>
            <h4>R$ {format(props.loanData.DesiredLoan)}</h4>
            <h5>Nro. de parcelas</h5>
            <h4>{props.loanData.Installments}x</h4>
            <h5>Taixa de juros</h5>
            <h4>3.70% a.m.</h4>
          </div>
        </div>
        <div className="observation">
          <div className="installments">
            <h5>Pagando em {props.loanData.Installments} parcelas de </h5>
            <h4>R$ {format((props.loanData.tax + props.loanData.DesiredLoan) / props.loanData.Installments)}</h4>
          </div>
          <p>* A taxa é personalizada e será calculada na análise de crédito. O Custo Efetivo Total (CET) é de {format(baseTax * 100 * 12)}% a.a..</p>
        </div>
      </div>
      <Tooltip
        title='Um e-mail válido precisa de um "@" e um domínio de topo (.com)' 
        placement="bottomLeft" 
        visible={!!RequesterEmail && !/([\d\w.]+@[\d\w]+\.)\w+/.test(RequesterEmail)}
      >
        <CustomInput
          title
          placeholder="e-mail"
          value={RequesterEmail || ''}
          onChange={e => setRequesterEmail(e.target.value)}
        />
      </Tooltip>
      <div className="check">
        <Checkbox checked={terms} onChange={e => setTerms(e.target.checked)}/>
        <h3>Concordo com os <span onClick={() => openModal(true)}>Termos de Uso</span> e as <span onClick={() => openModal(true)}>Políticas de Privacidade</span>.</h3>
      </div>
      <button 
        disabled={!RequesterEmail || !terms} 
        className="custom-button" 
        onClick={sendData}
      >quero empréstimo</button>
    </div>
  )
}

export default Confirm