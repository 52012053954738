import React from 'react';

import woman from './Assets/Images/banner_woman.png';
import womanMobile from './Assets/Images/img_woman_mobile.png';
import olaSomos from './Assets/Icons/ola_somos_giv.svg';
import simples from './Assets/Icons/eh_simples_eh_giv.svg';
import transparency from './Assets/Icons/ic_transparency.svg';
import humano from './Assets/Icons/ic_humano.svg';
import digital from './Assets/Icons/ic_digital.svg';

import './About.css';

function About () {
  return (
    <div id="about">
      <section id="greetings">
        {window.innerWidth > 800 ?
          (<img className="background" src={woman} alt="senhora"/>) :
          (<div className="background-mobile">
            <img src={womanMobile} alt="senhora"/>
            <div className="mobile-gradient"/>
          </div>)
        }
        <div className="description">
          <img src={olaSomos} alt="ola somos"/>
          <h2>A giv nasce de um sonho: transformar a economia da nossa região sul.</h2>
          <p>Sabemos do potencial dos gaúchos como empreendedores e sabemos que apostar nos teus sonhos significa nutrir o nosso.<br/><br/>
          Atuamos como ESC (Empresa Simples de Crédito), que é um novo tipo de negócio onde se realizam operações de empréstimos e financiamentos exclusivamente para <strong>microempreendedores individuais</strong> (MEI), <strong>microempresas</strong> e <strong>empresas de pequeno porte</strong>.</p>
        </div>
      </section>
      <section id="overview">
        <div className="texts">
          <h1>como teu empréstimo deve ser.</h1>
          <h2>Contribuir para o desenvolvimento do teu negócio pode ser simples e fácil.</h2>
          <a href="#borrow">quero empréstimo</a>
        </div>
        <div className="cards">
          <div className="card">
            <img src={digital} alt="digital"/>
            <h4>É digital.</h4>
            <p>Todo processo de empréstimo é seguro e 100% online.</p>
          </div>
          <div className="card">
            <img src={transparency} alt="transparency"/>
            <h4>É transparente.</h4>
            <p>A avaliação de crédito é rápida e sem complicações.</p>
          </div>
          <div className="card">
            <img src={humano} alt="humano"/>
            <h4>É humano.</h4>
            <p>Nossa equipe é formada por especialistas que te entendem e buscam sempre as melhores soluções.</p>
          </div>
          <div className="image">
            <img src={simples} alt="eh simples"/>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About