import React, { useState } from 'react';
import { Slider } from 'antd';

import './FirstStage.css';

export const minDesiredLoan = 1000;
export const maxDesiredLoan = 5000;
const defDesiredLoan = (minDesiredLoan + maxDesiredLoan) / 2;
const stepDesiredLoan = 500;

const minInstallments = 1;
const maxInstallments = 24;
const defInstallments = 12;
const stepInstallments = 1;

export const baseTax = 0.039

function calcTax(DesiredLoan, Installments) {
  return (DesiredLoan * Math.pow(1 + baseTax, Installments)) - DesiredLoan
}

function FirstStage(props) {
  const [DesiredLoan, setDesiredLoan] = useState(defDesiredLoan)
  const [Installments, setInstallments] = useState(defInstallments)

  const tax = calcTax(DesiredLoan, Installments)

  const goNext = () => props.setStage(1, {DesiredLoan, Installments, tax})
  const format = a => a.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})

  return (
    <div className="first-stage">
      <h1>qual valor ajudaria a tua empresa neste momento?</h1>
      <div className="form">
        <div className="sliders">
          <div className="slider">
            <div className="value">
              <h3>Valor desejado</h3>
            </div>
            <h2>R$ {format(DesiredLoan)}</h2>
            <Slider
              tooltipVisible={false}
              value={DesiredLoan} 
              onChange={setDesiredLoan} 
              step={stepDesiredLoan} 
              min={minDesiredLoan} 
              max={maxDesiredLoan} 
            />
            <div className="minmax">
              <h4 className="min">R$ {format(minDesiredLoan)}</h4>
              <h4 className="max">R$ {format(maxDesiredLoan)}</h4>
            </div>
          </div>
          <div className="slider">
            <div className="value">
              <h3>Número de parcelas</h3>
            </div>
            <h2>{Installments} Parcela{Installments > 1 ? 's' : ''}</h2>
            <Slider 
              tooltipVisible={false}
              value={Installments}
              onChange={setInstallments}
              min={minInstallments}
              max={maxInstallments}
              step={stepInstallments}
            />
            <div className="minmax">
              <h4 className="min">1x</h4>
              <h4 className="max">24x</h4>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="summary">
            <h2>Pagando em {Installments} parcela{Installments > 1 ? 's' : ''} de</h2>
            <h1>R$ {format((tax + DesiredLoan) / Installments)}</h1>
            <h6>Total de R$ {format(DesiredLoan)} de crédito + {format(baseTax * 100)}% a.m. de taxas*.</h6>
          </div>
          <div className="separator" />
          <div className="confirm">
            <h2>E aí, curtiu?</h2>
            <h3>Solicita crédito agora!</h3>
            <div className="next-button" onClick={goNext}>quero empréstimo</div>
          </div>
        </div>
        <h6>* A taxa é personalizada e será calculada na análise de crédito. Os juros do simulador apresentam taxas de {format(baseTax * 100)}% a.m. e de {format(baseTax * 100 * 12)}% a.a.</h6>
      </div>
    </div>
  )
}

export default FirstStage