import React, { useState } from "react";

import FirstStage from "./Forms/FirstStage";
import Objective from "./Forms/Objective";
import UserInfo from "./Forms/UserInfo";
import Company from "./Forms/Company";
import Confirm from "./Forms/Confirm";
import Done from "./Forms/Done";

import homen2 from './Assets/Images/img_homen2.png';
import queroEmprestimo from './Assets/Icons/quero_emprestimo.svg';
import done from './Assets/Icons/ic_check_ok.svg';
import disable from './Assets/Icons/ic_check_disable.svg';
import current from './Assets/Icons/ic_step_able.svg';

import './Borrow.css';

const steps = [
  {content: FirstStage},
  {content: Objective},
  {content: UserInfo},
  {content: Company},
  {content: Confirm},
  {content: Done}
];

let stepCount = 4

function Borrow () {
  const [step, setStep] = useState(0)
  const [loanData, setLoanData] = useState({})

  const Component = steps[step]

  const setStage = (step, loan) => {
    setLoanData({...loanData, ...loan})
    setStep(step)
    window.location.href = "#borrow"
  }

  const getIcon = (pos) => {
    if(pos === step -1) return current
    if(pos < step - 1) return done
    return disable
  }
 
  return (
    <section id="borrow">
      <img className="quero-emprestimo" src={queroEmprestimo} alt="emprestimo"/>
      <div className="form-container">
        {step !== 0 && step !== stepCount + 1 && (
          <div className="step-wrapper">
            <div className="steps">
              {new Array(stepCount).fill(undefined).map((e, i)=> (
                <div key={i} className="step">
                  <img src={getIcon(i)} alt="status"/>
                </div>
              ))}
            </div>
          </div>
        )}
        <Component.content setStage={setStage} loanData={loanData}/>
      </div>
      <img className="background" src={homen2} alt="homen"/>
    </section>
  )
}

export default Borrow