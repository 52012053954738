import React, { useState, useCallback } from 'react';
import ReactInputMask from 'react-input-mask';

import arrow from "../Assets/Icons/ic_arrow_left.svg";
import CustomInput from '../Components/CustomInput';
import { Select, Tooltip } from 'antd';
import drop from "../Assets/Icons/ic_drop.svg";

const SchollarshipEnum = {
  'Ensino fundamental incompleto': 1,
  'Ensino fundamental completo': 2,
  'Ensino médio incompleto': 3,
  'Ensino médio completo': 4,
  'Ensino superior incompleto': 5,
  'Ensino superior completo': 6,
  'Pós-graduação, mestrado ou doutorado': 7
}

function CpfValid (cpf) {
  cpf = cpf.replace(/[^\d]+/g,'') || ''
  if(cpf.length !== 11) return false

  if (cpf === "00000000000" || 
    cpf === "11111111111" || 
    cpf === "22222222222" || 
    cpf === "33333333333" || 
    cpf === "44444444444" || 
    cpf === "55555555555" || 
    cpf === "66666666666" || 
    cpf === "77777777777" || 
    cpf === "88888888888" || 
    cpf === "99999999999")
      return false;
      
  let add = 0;
  for (let i = 0; i < 9; i ++)		
    add += parseInt(cpf.charAt(i)) * (10 - i);	
    let rev = 11 - (add % 11);	
    if (rev === 10 || rev === 11)		
      rev = 0;	
    if (rev !== parseInt(cpf.charAt(9)))		
      return false;		

  add = 0;	
  for (let i = 0; i < 10; i ++)		
    add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
  if (rev === 10 || rev === 11)	
    rev = 0;	
  if (rev !== parseInt(cpf.charAt(10)))
    return false;		
  return true;   
}

function UserInfo(props) {
  const [RequesterName, setRequesterName] = useState('')
  const [RequesterEmail, setRequesterEmail] = useState('')
  const [RequesterCpf, setRequesterCpf] = useState('')
  const [RequesterPhone, setRequesterPhone] = useState('')
  const [SchollarshipType, setSchollarship] = useState()

  const goBack = () => props.setStage(1)
  const goNext = () => props.setStage(3, {RequesterName, RequesterEmail, RequesterCpf, RequesterPhone, SchollarshipType })

  const isCpfValid = useCallback(CpfValid, [RequesterCpf])

  return (
    <div className="user-info">
      <div className="form-title">
        <img src={arrow} className="go-back" alt="go back" onClick={goBack}/>
        <h1>conte um pouco sobre você.</h1>
      </div>
      <Tooltip 
        title='Um nome válido precisa ter no mínimo 4 caracteres' 
        placement="bottomLeft" 
        visible={!!RequesterName && RequesterName.length < 4}
      >
        <CustomInput 
          title
          placeholder="nome completo"
          value={RequesterName || ''}
          onChange={e => setRequesterName(e.target.value)}
        />
      </Tooltip>
      <Tooltip 
        title='Um e-mail válido precisa de um "@" e um domínio de topo (.com)' 
        placement="bottomLeft" 
        visible={!!RequesterEmail && !/([\d\w.]+@[\d\w]+\.)\w+/.test(RequesterEmail)}
      >
        <CustomInput
          title
          placeholder="e-mail"
          value={RequesterEmail || ''}
          onChange={e => setRequesterEmail(e.target.value)}
        />
      </Tooltip>
      <Tooltip
        title='CPF inválido' 
        placement="bottomLeft" 
        visible={RequesterCpf.replace(/[^\d]+/g,'').length === 11 && !isCpfValid(RequesterCpf)}
      >
        <ReactInputMask
          value={RequesterCpf || ''}
          onChange={e => setRequesterCpf(e.target.value)}
          mask="999.999.999-99"
        >{
          p => <CustomInput title placeholder="CPF" {...p}/>
        }</ReactInputMask>
      </Tooltip>
      <ReactInputMask
        value={RequesterPhone || ''}
        onChange={e => setRequesterPhone(e.target.value)}
        mask="(99) \99999-9999"
      >{
        p => <CustomInput title placeholder="telefone" {...p}/>
      }</ReactInputMask>
      <Select 
        onChange={v => setSchollarship(parseInt(v, 10))} 
        placeholder="escolaridade" 
        className="custom-select" 
        suffixIcon={<img src={drop} alt="dropdown"/>}
      >
        {Object.entries(SchollarshipEnum).map(el => <Select.Option key={el[1]}>{el[0]}</Select.Option>)}
      </Select>
      <button 
        disabled={
          !/([\d\w.]+@[\d\w]+\.)\w+/.test(RequesterEmail) || 
          !RequesterName.length || !isCpfValid(RequesterCpf) ||
          RequesterPhone.replace(/\D/g,'').length !== 11 ||
          !SchollarshipType
        } 
        className="custom-button" 
        onClick={goNext}
      >próximo</button>
    </div>
  )
}

export default UserInfo