import React from 'react';
import depoimentos from './Assets/Icons/depoimentos.svg';

import './Comments.css';

function Comments () {
  return (
    <section id="comments">
      <div className="header">
        <img className="title" src={depoimentos} alt="depoimentos"/>
      </div>
      <div className="steps">
        <div className="step">
          <p>Fiquei muito satisfeito com o atendimento de toda equipe da GIV. Mostraram-se interessados em como poderiam ajudar meu negócio a crescer mais. Recomendo!</p>
          <h3>Carlos Eduardo</h3>
          <h4>Cachoeirinha</h4>
        </div>
        <div className="step">
          <p>Para mim, todo o processo foi muito bom e tranquilo. O crédito ajudou na reforma da minha lojinha. Atendimento prático e rápido!</p>
          <h3>Edimara</h3>
          <h4>Porto Alegre</h4>
        </div>
        <div className="step">
          <p>A GIV me ajudou no momento de expansão do meu negócio. Acreditaram no meu trabalho e me proporcionaram uma linha de crédito para podermos ampliar a nossa operação. Obrigado GIV!</p>
          <h3>Daniel</h3>
          <h4>Porto Alegre</h4>
        </div>
      </div>
    </section>
  )
}

export default Comments