import React from 'react'
import { Input } from 'antd'

function CustomInput (props) {
  return (
    <label className="custom-input" >
      {props.title ? <h4 className={props.value ? "visible" : ''}>{props.placeholder}</h4> : null}
      <Input {...props} title=''/>
    </label>
  )
}

export default CustomInput
