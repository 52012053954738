import React, { useContext } from 'react'
import { Modal } from "antd";

import * as PolicyAndTerms  from './Definitions/termAndPolicy.json'
import { PolicyAndTermsModalCtx } from './App.js';

import './PolicyAndTermsModal.css'

function PolicyAndTermsModal (props){
  const openModal = useContext(PolicyAndTermsModalCtx)

  return (
    <Modal className="policy-and-terms" visible={props.open} onCancel={() => openModal(false)}>
      <h1>{PolicyAndTerms.title}</h1>
      <h2>{PolicyAndTerms.terms.title}</h2>
      <p>{PolicyAndTerms.terms.description}</p>
      {PolicyAndTerms.terms.paragraphs.map((p, i) => (
        <div key={i}>
          <h3>{p.title}</h3>
          <p>{p.content}</p>
        </div>
      ))}
      <h2>{PolicyAndTerms.policy.title}</h2>
      <p>{PolicyAndTerms.policy.description}</p>
      {PolicyAndTerms.policy.paragraphs.map((p, i) => (
        <div key={i}>
          <h3>{p.title}</h3>
          <p>{p.content}</p>
        </div>
      ))}
      <h4>{PolicyAndTerms.location}</h4>
      <button className="custom-button" onClick={() => openModal(false)}>OK</button>
    </Modal>
  )
}

export default PolicyAndTermsModal