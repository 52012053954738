import React, { useState } from 'react';
import { Select } from 'antd';

import arrow from "../Assets/Icons/ic_arrow_left.svg";
import drop from "../Assets/Icons/ic_drop.svg";
import CustomInput from '../Components/CustomInput';

const ObjectiveEnum = {
  'Capital de giro': 4,
  'Compra de equipamentos': 5,
  'Compra de estoque': 6,
  'Consolidação de dívidas': 7,
  'Expansão': 8,
  'Marketing e vendas': 9,
  'Reforma': 10,
  'Uso pessoal': 11,
  'Outros': 3,
}

function Objective(props) {
  const [ObjectiveType, setObjective] = useState()
  const [ObjectiveDescription, setObjectiveDescription] = useState('')

  const goBack = () => props.setStage(0, {})
  const goNext = () => props.setStage(2, { ObjectiveType, ObjectiveDescription})

  return (
    <div className="purpose">
      <div className="form-title">
        <img src={arrow} className="go-back" alt="go back" onClick={goBack}/>
        <h1>como pretende usar o empréstimo?</h1>
      </div>
      <Select 
          onChange={v => setObjective(parseInt(v, 10))} 
          placeholder="clique para escolher" 
          className="custom-select" 
          suffixIcon={<img src={drop} alt="dropdown"/>}
        >
          {Object.entries(ObjectiveEnum).map(el => <Select.Option key={el[1]}>{el[0]}</Select.Option>)}
      </Select>
      {ObjectiveType === 3 ? (
        <CustomInput
          placeholder="qual?"
          value={ObjectiveDescription || ''}
          onChange={e => setObjectiveDescription(e.target.value)}
        />
      ) : null}
      <button 
        disabled={ObjectiveType === 3 ? !ObjectiveDescription : !ObjectiveType} 
        className="custom-button" 
        onClick={goNext}
      >próximo</button>
    </div>
  )
}

export default Objective