import React, { useContext } from 'react';

import email from './Assets/Icons/ic_email.svg';
import instagram from './Assets/Icons/ic_instagram.svg';
import facebook from './Assets/Icons/ic_facebook.svg';
import pin from './Assets/Icons/ic_pin.svg';
import lock from './Assets/Icons/ic_lock.svg';

import './Footer.css';
import { PolicyAndTermsModalCtx } from './App';
import { maxDesiredLoan, minDesiredLoan } from './Forms/FirstStage';

function Footer () {
  const openModal = useContext(PolicyAndTermsModalCtx)

  const format = a => a.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})

  return (
    <footer id="contact">
      <div className="info">
        <article>
          <h3>Vamos conversar?</h3>
          <h4>Entra em contato conosco.</h4>
        </article>
        <article className="busines">
          <div>
            <h5>Endereço</h5>
            <p><img src={pin} alt="location"/>
              <span>Av. Cristóvão Colombo,<br/> 
              2955 9º andar - Floresta, <br/>
              Porto Alegre - RS - Brasil</span>
            </p>
          </div>
          <div>
            <h5>Contato</h5>
            <p>
              <img src={email} alt="email"/>
              <span>ola@somosgiv.com.br</span>
            </p>
          </div>
          <div className="social">
            <h5>Redes Sociais</h5>
            <div className="buttons">
              <a href="https://www.instagram.com/somosgiv" target="blank"><img src={instagram} alt="instagram"/></a>
              <a href="http://facebook.com/somosgiv" target="blank"><img src={facebook} alt="facebook"/></a>
            </div>
          </div>
        </article>
        <article className="lock">
          <p>
            <img src={lock} alt="lock"/>
            <span>Site Seguro</span>
          </p>
        </article>
        <article className="company-info">
          <p>©2019-2020 Francred Empresa Simples de Crédito Ltda. (CNPJ: 34.999.872/0001-27). Todos os direitos reservados.</p> 
          <p>Prazo de pagamento: de 3 a 24 meses. Valores de R$ {format(minDesiredLoan)} a R$ {format(maxDesiredLoan)}. Juros Mensal de 3,9% a 8% a.m, sendo 46,8% a.a. a 96,0% a.a.. Exemplo: um empréstimo de R$ 5.000,00 com pagamento em 12 parcelas <b>R$ 659,44</b>. O valor total a ser pago será de <b>R$ 7.913,28</b>, com juros de 3,9% a.m. Na operação será descontado IOF conforme lei Lei nº 9.779/1999. A Francred Empresa Simples de Crédito Ltda. (CNPJ: 34.999.872/0001-27) não é um banco ou uma instituição financeira. Oferecemos uma plataforma digital de acesso ao crédito para pequenos empresários. Atuamos como uma Empresa Simples de Crédito regulada pela CERC. A atividade de Empresa Simples de Crédito é regulada pelo Banco Central do Brasil. Para maiores informações, consulte a LEI COMPLEMENTAR Nº 167, DE 24 DE ABRIL DE 2019. Ao utilizar nossa plataforma, os empréstimos são realizados por utilização de recursos próprios.</p>
        </article>
      </div>
      <div className="copyright">
        <h4 className="terms-and-policy" onClick={() => openModal(true)}>Termos de Uso | Política de Privacidade</h4>
      </div>
    </footer>
  )
}

export default Footer