import React from 'react';
import wait from "../Assets/Icons/ic_wait.svg";

import './Done.css';

function Done(props) {
  const goToStart = () => props.setStage(0, {})
  const format = a => a.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})

  return (
    <div className="confirm done">
      <div className="form-title">
        <img src={wait} alt="wait"/>
        <h1>Tudo certo {props.loanData.name}!</h1>
        <h2>todas suas informações foram enviadas com sucesso.</h2>
      </div>
      <div className="card">
        <div className="summary">
          <h3>Resumo da simulação</h3>
          <div className="data">
            <h5>Valor do empréstimo</h5>
            <h4>R$ {format(props.loanData.DesiredLoan)}</h4>
            <h5>Nro. de parcelas</h5>
            <h4>{props.loanData.Installments}x</h4>
            <h5>Taixa de juros</h5>
            <h4>3.70% a.m.</h4>
          </div>
        </div>
        <div className="observation">
          <div className="installments">
            <h5>Pagando em {props.loanData.Installments} parcelas de </h5>
            <h4>R$ {format((props.loanData.tax + props.loanData.DesiredLoan) / props.loanData.Installments)}</h4>
          </div>
          <p>* A taxa é personalizada e será calculada na análise de crédito. O Custo Efetivo Total (CET) é de 44,40% a.a..</p>
        </div>
      </div>
      <h2>em breve nossa equipe entrará em contato com você!</h2>
      <button 
        className="custom-button" 
        onClick={goToStart}
      >nova solicitação</button>
    </div>
  )
}

export default Done