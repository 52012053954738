import React, { createContext, useState } from 'react';
import { BackTop, Icon, ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import 'moment/locale/pt-br';

import Home from './Home';
import About from './About';
import Method from './Method';
import Comments from './Comments';
import Footer from './Footer';
import Borrow from './Borrow';

import './App.css';
import PolicyAndTermsModal from './PolicyAndTermsModal';

export const PolicyAndTermsModalCtx = createContext()

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ConfigProvider locale={ptBR}>
      <PolicyAndTermsModalCtx.Provider value={setIsModalOpen}>
        <PolicyAndTermsModal open={isModalOpen} />
        <div className="App">
          <Home />
          <About />
          <Method />
          <Comments />
          <Borrow />
          <Footer />
          <BackTop>
            <Icon type="up" />
          </BackTop>
        </div>
      </PolicyAndTermsModalCtx.Provider>
    </ConfigProvider>
  );
}

/*parseDoc content to json
function parseDoc (text) {
    let arrs = text.split('\n')
    let index = -1
    return arrs.reduce((a,e) => {
        if((e || '').split('').some(e => /\d/.test(e))){
            a.push({
                title: e,
                content: ''
            })
            index++
        }else {
            if(a[index] === undefined) a[index] = {content: ""}
            a[index].content += e || ''
        }
        return a
    },[])
}
*/

export default App;
