import React from 'react';
import comoFunciona from './Assets/Icons/como_funciona.svg';
import homen1 from './Assets/Images/img_homen1.png';

import './Method.css';

function Method () {
  return (
    <section id="method">
      <img className="background" src={homen1} alt="como funciona"/>
      <div>
        <img className="how" src={comoFunciona} alt="como funciona"/>
        <h2>3 passos para tornar teu sonho realidade!</h2>
      </div>
      <div className="steps">
        <div className="step">
          <h1>1</h1>
          <h3>Preenche o formulário.</h3>
          <p>Preenche o formulário com dados da tua empresa e informa o valor desejado.</p>
        </div>
        <div className="step">
          <h1>2</h1>
          <h3>Aguarda a análise.</h3>
          <p>Após uma análise financeira da nossa equipe, tu recebes um e-mail com o retorno do pedido.</p>
        </div>
        <div className="step">
          <h1>3</h1>
          <h3>Envia os documentos.</h3>
          <p>Caso teu histórico financeiro seja aprovado, basta enviar documentação necessária e teu crédito é liberado.</p>
        </div>
      </div>
      <h3>viu como é simples! :) <br/>Agora é só se dedicar e fazer o teu negócio decolar.</h3> 
    </section>
  )
}

export default Method